import React, { useState, useEffect } from "react";
import "./TravelerForm.css";

const TravelerForm = ({ onTravelerChange }) => {
  const [selectedClass, setSelectedClass] = useState("economy");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClassChange = (classType) => {
    setSelectedClass(classType);
  };

  const toggleExpand = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  // Обновляем все данные о пассажирах одновременно
  const updateTravelerData = (newAdults, newChildren, newInfants) => {
    setAdults(newAdults);
    setChildren(newChildren);
    setInfants(newInfants);
    onTravelerChange([
      { type: "ADULT", quantity: newAdults },
      { type: "CHILD", quantity: newChildren },
      { type: "HELD_INFANT", quantity: newInfants },
    ]);
  };

  const handleQuantityChange = (type, change) => {
    switch (type) {
      case "adults":
        updateTravelerData(Math.max(0, adults + change), children, infants);
        break;
      case "children":
        updateTravelerData(adults, Math.max(0, children + change), infants);
        break;
      case "infants":
        updateTravelerData(adults, children, Math.max(0, infants + change));
        break;
      default:
        break;
    }
  };

  return (
    <div className="traveler-form">
      <button type="button" className="toggle-button" onClick={toggleExpand}>
        {isExpanded ? "Скрыть" : "Выберите пассажиров"}
      </button>

      <div className={`traveler-content ${isExpanded ? "show" : ""}`}>
        <div className="class-selector">
          <button
            className={selectedClass === "economy" ? "active" : ""}
            onClick={() => handleClassChange("economy")}
          >
            Эконом
          </button>
        </div>

        <div className="traveler-selector">
          <div className="traveler-type">
            <div className="type-info">
              <p className="type-title">Взрослые</p>
              <p className="age-range">От 15 лет</p>
            </div>
            <div className="quantity-control">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("adults", -1);
                }}
              >
                -
              </button>
              <span>{adults}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("adults", 1);
                }}
              >
                +
              </button>
            </div>
          </div>

          <div className="traveler-type">
            <div>
              <p>Дети</p>
              <p className="age-range">От 2 до 14 лет</p>
            </div>
            <div className="quantity-control">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("children", -1);
                }}
              >
                -
              </button>
              <span>{children}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("children", 1);
                }}
              >
                +
              </button>
            </div>
          </div>

          <div className="traveler-type">
            <div className="type-info">
              <p className="type-title">Младенцы</p>
              <p className="age-range">До 2 лет</p>
            </div>
            <div className="quantity-control">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("infants", -1);
                }}
              >
                -
              </button>
              <span>{infants}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleQuantityChange("infants", 1);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelerForm;
