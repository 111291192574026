import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchFlightOffers = async ({
  originLocationCode,
  destinationLocationCode,
  departureDate,
  returnDate,
  travelerData,
  maxFlightOffers = 10,
}) => {
  const formatDate = (date) => {
    if (typeof date === "string") return date;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  let idCounter = 1;
  const travelers = travelerData.flatMap((traveler) =>
    Array.from({ length: traveler.quantity }).map(() => ({
      id: `${idCounter++}`,
      travelerType: traveler.type,
    }))
  );

  // Формируем список направлений, добавляем обратный рейс только если есть returnDate
  const originDestinations = [
    {
      id: "1",
      originLocationCode,
      destinationLocationCode,
      departureDateTimeRange: { date: formatDate(departureDate) },
    },
  ];

  // Добавляем обратное направление только если returnDate установлен
  if (returnDate) {
    originDestinations.push({
      id: "2",
      originLocationCode: destinationLocationCode,
      destinationLocationCode: originLocationCode,
      departureDateTimeRange: { date: formatDate(returnDate) },
    });
  }

  const requestData = {
    originDestinations,
    travelers,
    maxFlightOffers,
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api-temfly-test/v2/shopping/flight-offers`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
      }
    );

    return response.data.original.data || [];
  } catch (error) {
    throw new Error(
      "Ошибка при получении данных: " + (error.response?.data || error.message)
    );
  }
};
