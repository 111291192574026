import React from "react";
import FlightOffers from "../components/FlightOffers/FlightOffers";
import SearchForm from "../components/SearchForm/SearchForm";
import useFlightOffers from "../hooks/useFlightOffers";

const FlightOffersPage = () => {
  const {
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    originLocationCode,
    setOriginLocationCode,
    destinationLocationCode,
    setDestinationLocationCode,
    travelerData,
    handleTravelerChange,
    fetchFlightOffers,
    isLoading,
    error,
    flightOffers,
  } = useFlightOffers();

  return (
    <div className="page">
      <nav>
        <div className="nav__logo">Flivan</div>
        <ul className="nav__links">
          <li className="link">
            <a href="/">Home</a>
          </li>
          <li className="link">
            <a href="/">About</a>
          </li>
          <li className="link">
            <a href="/">Offers</a>
          </li>
          <li className="link">
            <a href="/">Seats</a>
          </li>
          <li className="link">
            <a href="/">Destinations</a>
          </li>
        </ul>
        <button className="btn">Contact</button>
      </nav>
      <header className="section__container header__container">
        <h1 className="section__header">
          Find And Book <br />A Great Experience
        </h1>
        <img src="/assets/header.jpg" width={700} height={300} alt="header" />
      </header>

      {/* Форма для поиска предложений */}
      <SearchForm
        originLocationCode={originLocationCode}
        destinationLocationCode={destinationLocationCode}
        departureDate={departureDate}
        returnDate={returnDate}
        travelerData={travelerData}
        isLoading={isLoading}
        onOriginSelect={setOriginLocationCode}
        onDestinationSelect={setDestinationLocationCode}
        onDepartureDateChange={setDepartureDate}
        onReturnDateChange={setReturnDate}
        onTravelerChange={handleTravelerChange}
        onSubmit={fetchFlightOffers}
      />

      {/* Вывод предложений */}
      <section className="section__container offers__container">
        <center>
          <h2 className="section__header">Доступные предложения</h2>
        </center>
        {error && <p className="error-message">{error}</p>}
        {!isLoading && !error && <FlightOffers offers={flightOffers} />}
      </section>
    </div>
  );
};

export default FlightOffersPage;
