import React, { useState } from "react";
import FromCity from "../FromCity";
import ToCity from "../ToCity";
import Calendar from "../Calendar/Calendar";
import TravelerForm from "../TravelerForm/TravelerForm";
import Loader from "../Loader/Loader";
import "./SearchForm.css"; // Импортируем отдельный файл стилей

const SearchForm = ({
  originLocationCode,
  destinationLocationCode,
  departureDate,
  returnDate,
  travelerData,
  isLoading,
  onOriginSelect,
  onDestinationSelect,
  onDepartureDateChange,
  onReturnDateChange,
  onTravelerChange,
  onSubmit,
}) => {
  const [noReturnTicket, setNoReturnTicket] = useState(false);

  const handleNoReturnTicketChange = (e) => {
    const checked = e.target.checked;
    setNoReturnTicket(checked);
    if (checked) {
      onReturnDateChange(null); // Сбрасываем дату обратного билета, если чекбокс активен
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Предотвращаем дефолтное поведение
    onSubmit(e); // Передаем event в onSubmit
  };
  return (
    <section className="section__container booking__container">
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="search-form">
          <div className="form__row">
            <FromCity onSelect={onOriginSelect} />
            <ToCity onSelect={onDestinationSelect} />
          </div>

          <div className="form__row">
            <TravelerForm onTravelerChange={onTravelerChange} />
            <div className="date-container">
              <Calendar
                onDateChange={onDepartureDateChange}
                label="Дата отправления"
                originLocationCode={originLocationCode}
                destinationLocationCode={destinationLocationCode}
              />
            </div>
            <div className="return-date-container">
              <Calendar
                onDateChange={onReturnDateChange}
                label="Дата возвращения"
                initialDate={returnDate || null}
                originLocationCode={destinationLocationCode}
                destinationLocationCode={originLocationCode}
                disabled={noReturnTicket} // Заблокируем ввод, если чекбокс выбран
              />
              <label className="no-return-label">
                <input
                  type="checkbox"
                  checked={noReturnTicket}
                  onChange={handleNoReturnTicketChange}
                  className="no-return-checkbox"
                />
                Обратный билет не нужен
              </label>
            </div>
          </div>

          <button type="submit" className="btn">
            Найти рейсы
          </button>
        </form>
      )}
    </section>
  );
};

export default SearchForm;
