import React from "react";
import { useNavigate } from "react-router-dom";
import SearchForm from "../components/SearchForm/SearchForm";
import useFlightOffers from "../hooks/useFlightOffers";

const HomePage = () => {
  const {
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    originLocationCode,
    setOriginLocationCode,
    destinationLocationCode,
    setDestinationLocationCode,
    travelerData,
    handleTravelerChange,
    fetchFlightOffers,
    isLoading,
    error,
    flightOffers,
  } = useFlightOffers();

  const navigate = useNavigate();

  const handleFetchFlightOffers = async (event) => {
    event.preventDefault(); // Предотвращаем действие по умолчанию
    try {
      await fetchFlightOffers(); // Убедитесь, что fetchFlightOffers возвращает данные
      navigate("/offers"); // Навигация после успешного выполнения
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };

  return (
    <div className="page">
      <nav>
        <div className="nav__logo">Flivan</div>
        <ul className="nav__links">
          <li className="link">
            <a href="/">Home</a>
          </li>
          <li className="link">
            <a href="/">About</a>
          </li>
          <li className="link">
            <a href="/">Offers</a>
          </li>
          <li className="link">
            <a href="/">Seats</a>
          </li>
          <li className="link">
            <a href="/">Destinations</a>
          </li>
        </ul>
        <button className="btn">Contact</button>
      </nav>
      <header className="section__container header__container">
        <h1 className="section__header">
          Find And Book <br />A Great Experience
        </h1>
        <img src="/assets/header.jpg" width={700} height={300} alt="header" />
      </header>
      <SearchForm
        originLocationCode={originLocationCode}
        destinationLocationCode={destinationLocationCode}
        departureDate={departureDate}
        returnDate={returnDate}
        travelerData={travelerData}
        isLoading={isLoading}
        onOriginSelect={setOriginLocationCode}
        onDestinationSelect={setDestinationLocationCode}
        onDepartureDateChange={setDepartureDate}
        onReturnDateChange={setReturnDate}
        onTravelerChange={handleTravelerChange}
        onSubmit={handleFetchFlightOffers} // Передаем handleFetchFlightOffers
      />
      {error && <div className="error">Ошибка: {error}</div>}

      <section className="section__container lounge__container">
        <div className="lounge__image">
          <img
            src="/assets/lounge-1.jpg"
            alt="lounge"
            width={200} // Ширина по умолчанию (можно настроить)
            height={500}
          />
          <img
            src="/assets/lounge-2.jpg"
            alt="lounge"
            width={200} // Ширина по умолчанию (можно настроить)
            height={300}
          />
        </div>
        <div className="lounge__content">
          <h2 className="section__header">Unaccompanied Minor Lounge</h2>
          <div className="lounge__grid">
            <div className="lounge__details">
              <h4>Experience Tranquility</h4>
              <p>
                Serenity Haven offers a tranquil escape, featuring comfortable
                seating, calming ambiance, and attentive service.
              </p>
            </div>
            <div className="lounge__details">
              <h4>Elevate Your Experience</h4>
              <p>
                Designed for discerning travelers, this exclusive lounge offers
                premium amenities, assistance, and private workspaces.
              </p>
            </div>
            <div className="lounge__details">
              <h4>A Welcoming Space</h4>
              <p>
                Creating a family-friendly atmosphere, The Family Zone is the
                perfect haven for parents and children.
              </p>
            </div>
            <div className="lounge__details">
              <h4>A Culinary Delight</h4>
              <p>
                Immerse yourself in a world of flavors, offering international
                cuisines, gourmet dishes, and carefully curated beverages.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section__container travellers__container">
        <h2 className="section__header">Best travellers of the month</h2>
        <div className="travellers__grid">
          <div className="travellers__card">
            <img
              src="/assets/traveller-1.jpg"
              alt="traveller"
              width={200} // Ширина по умолчанию (можно настроить)
              height={300}
            />
            <div className="travellers__card__content">
              <img
                src="/assets/client-1.jpg"
                alt="client"
                width={80} // Ширина по умолчанию (можно настроить)
                height={70}
              />
              <h4>Emily Johnson</h4>
              <p>Dubai</p>
            </div>
          </div>
          <div className="travellers__card">
            <img
              src="/assets/traveller-2.jpg"
              alt="traveller"
              width={200} // Ширина по умолчанию (можно настроить)
              height={300}
            />
            <div className="travellers__card__content">
              <img
                src="/assets/client-2.jpg"
                alt="client"
                width={80} // Ширина по умолчанию (можно настроить)
                height={70}
              />
              <h4>David Smith</h4>
              <p>Paris</p>
            </div>
          </div>
          <div className="travellers__card">
            <img
              src="/assets/traveller-3.jpg"
              alt="traveller"
              width={200} // Ширина по умолчанию (можно настроить)
              height={300}
            />
            <div className="travellers__card__content">
              <img
                src="/assets/client-3.jpg"
                alt="client"
                width={80} // Ширина по умолчанию (можно настроить)
                height={70}
              />
              <h4>Olivia Brown</h4>
              <p>Singapore</p>
            </div>
          </div>
          <div className="travellers__card">
            <img
              src="/assets/traveller-4.jpg"
              alt="traveller"
              width={200} // Ширина по умолчанию (можно настроить)
              height={300}
            />
            <div className="travellers__card__content">
              <img
                src="/assets/client-4.jpg"
                alt="client"
                width={80} // Ширина по умолчанию (можно настроить)
                height={70}
              />
              <h4>Daniel Taylor</h4>
              <p>Malaysia</p>
            </div>
          </div>
        </div>
      </section>

      <section className="subscribe">
        <div className="section__container subscribe__container">
          <h2 className="section__header">
            Subscribe newsletter & get latest news
          </h2>
          <form className="subscribe__form">
            <input type="text" placeholder="Enter your email here" />
            <button className="btn">Subscribe</button>
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="section__container footer__container">
          <div className="footer__col">
            <h3>Flivan</h3>
            <p>
              Where Excellence Takes Flight. With a strong commitment to
              customer satisfaction and a passion for air travel, Flivan
              Airlines offers exceptional service and seamless journeys.
            </p>
            <p>
              From friendly smiles to state-of-the-art aircraft, we connect the
              world, ensuring safe, comfortable, and unforgettable experiences.
            </p>
          </div>
          <div className="footer__col">
            <h4>INFORMATION</h4>
            <p>Home</p>
            <p>About</p>
            <p>Offers</p>
            <p>Seats</p>
            <p>Destinations</p>
          </div>
          <div className="footer__col">
            <h4>CONTACT</h4>
            <p>Support</p>
            <p>Media</p>
            <p>Socials</p>
          </div>
        </div>
        <div className="section__container footer__bar">
          <p>Copyright © 2023 Web Design Mastery. All rights reserved.</p>
          <div className="socials">
            <span>
              <i className="ri-facebook-fill"></i>
            </span>
            <span>
              <i className="ri-twitter-fill"></i>
            </span>
            <span>
              <i className="ri-instagram-line"></i>
            </span>
            <span>
              <i className="ri-youtube-fill"></i>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
