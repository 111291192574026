import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css";

const Calendar = ({ onDateChange, label, disabled, initialDate = null }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Обновляем выбранную дату, если initialDate изменяется
    setSelectedDate(initialDate);
  }, [initialDate]);

  const handleChange = (date) => {
    setSelectedDate(date);
    onDateChange(date ? format(date, "yyyy-MM-dd") : "");
    setIsOpen(false);
  };

  const toggleDatePicker = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };
  const formattedDate = selectedDate
    ? format(selectedDate, "d MMMM, EEEE", { locale: ru }).replace(
        /^./,
        (char) => char.toUpperCase()
      )
    : "";

  return (
    <div className="calendar">
      <input
        type="text"
        value={formattedDate}
        onClick={toggleDatePicker}
        readOnly
        placeholder="Выберите дату"
        className="calendar__input"
        disabled={disabled} // Заблокируем input при активном чекбоксе
      />
      {isOpen && (
        <div className="calendar__datepicker">
          <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            onClickOutside={() => setIsOpen(false)}
            minDate={new Date()}
            locale={ru}
            inline
          />
        </div>
      )}
    </div>
  );
};

export default Calendar;
