import { useState, useEffect } from "react";
import { fetchFlightOffers as apiFetchFlightOffers } from "../services/api";

const useFlightOffers = () => {
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [originLocationCode, setOriginLocationCode] = useState("");
  const [destinationLocationCode, setDestinationLocationCode] = useState("");
  const [travelerData, setTravelerData] = useState([
    { type: "ADULT", quantity: 1 },
    { type: "CHILD", quantity: 0 },
    { type: "HELD_INFANT", quantity: 0 },
  ]);
  const [flightOffers, setFlightOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Загружаем предложения из sessionStorage при первой загрузке
  useEffect(() => {
    const storedOffers = JSON.parse(
      sessionStorage.getItem("flightOffers") || "[]"
    );
    if (storedOffers.length > 0) {
      setFlightOffers(storedOffers);
    }
  }, []);

  const handleTravelerChange = (newTravelerData) => {
    setTravelerData(newTravelerData);
  };

  const fetchFlightOffers = async () => {
    const totalTravelers = travelerData.reduce(
      (total, traveler) => total + traveler.quantity,
      0
    );
    if (totalTravelers === 0) {
      alert("Выберите хотя бы одного пассажира.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const offers = await apiFetchFlightOffers({
        originLocationCode,
        destinationLocationCode,
        departureDate,
        returnDate,
        travelerData,
      });
      setFlightOffers(offers);
      sessionStorage.setItem("flightOffers", JSON.stringify(offers)); // Сохраняем данные
    } catch (error) {
      setError("Ошибка при получении данных: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    originLocationCode,
    setOriginLocationCode,
    destinationLocationCode,
    setDestinationLocationCode,
    travelerData,
    handleTravelerChange,
    flightOffers,
    fetchFlightOffers,
    isLoading,
    error,
  };
};

export default useFlightOffers;
