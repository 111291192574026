import React from "react";
import "./FlightOffers.css";

const FlightOffers = ({ offers }) => {
  return (
    <div className="flight-offers">
      {Array.isArray(offers) && offers.length > 0 ? (
        offers.map((offer, index) => {
          const price = offer.price || {};
          const outboundItinerary = offer.itineraries?.[0];
          const returnItinerary = offer.itineraries?.[1];

          const renderSegmentInfo = (segment) => {
            if (!segment) return null;

            const departureTime = new Date(
              segment.departure?.at
            ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            const arrivalTime = new Date(
              segment.arrival?.at
            ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            const departureDate = new Date(
              segment.departure?.at
            ).toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "short",
              weekday: "short",
            });
            const arrivalDate = new Date(
              segment.arrival?.at
            ).toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "short",
              weekday: "short",
            });

            const duration = calculateDuration(
              segment.departure?.at,
              segment.arrival?.at
            );

            return (
              <div className="segment-info">
                <div className="time-location">
                  <span className="time">{departureTime}</span>
                  <span className="location">
                    {segment.departure?.iataCode || "N/A"}
                  </span>
                  <span className="date">{departureDate}</span>
                </div>
                <div className="arrow">→</div>
                <div className="time-location">
                  <span className="time">{arrivalTime}</span>
                  <span className="location">
                    {segment.arrival?.iataCode || "N/A"}
                  </span>
                  <span className="date">{arrivalDate}</span>
                </div>
                <div className="duration">В пути: {duration}</div>
              </div>
            );
          };

          const calculateDuration = (departure, arrival) => {
            const departureDate = new Date(departure);
            const arrivalDate = new Date(arrival);
            const diffMs = arrivalDate - departureDate;
            const hours = Math.floor(diffMs / (1000 * 60 * 60));
            const minutes = Math.floor(
              (diffMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            return `${hours} ч ${minutes} мин`;
          };

          return (
            <div key={index} className="flight-offer-card">
              <div className="offer-header">
                <span className="flight-number">
                  {outboundItinerary?.segments?.[0]?.carrierCode || "N/A"}{" "}
                  {outboundItinerary?.segments?.[0]?.number || "N/A"}
                </span>
                <button className="price-button">
                  {price.total || "N/A"} {price.currency || "KZT"}
                </button>
              </div>
              <div
                className={`flight-details ${
                  !returnItinerary ? "single-direction" : ""
                }`}
              >
                <div className="direction">
                  <h4>Туда:</h4>
                  {renderSegmentInfo(outboundItinerary?.segments?.[0])}
                </div>
                {returnItinerary && (
                  <div className="direction">
                    <h4>Обратно:</h4>
                    {renderSegmentInfo(returnItinerary?.segments?.[0])}
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p className="no-offers">Рейсов не найдено.</p>
      )}
    </div>
  );
};

export default FlightOffers;
