// src/components/FromCity.js
import React, { useState } from "react";
import "./CountrySearch.css";
const apiUrl = process.env.REACT_APP_API_URL;

const ToCity = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const searchCitiesAndAirports = async (term) => {
    if (!term) {
      setError("Введите запрос для поиска");
      return;
    }

    setError(null);

    try {
      const response = await fetch(
        `${apiUrl}/api-temfly-test/v1/directories/search/cities-info?search=${term}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Ошибка: ${response.status}`);
      }

      const data = await response.json();
      setResults(data);
      setShowDropdown(true);
    } catch (err) {
      console.error(err.message);
      setError("Ошибка при выполнении запроса.");
    }
  };

  const handleChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length > 1) {
      searchCitiesAndAirports(term);
    } else {
      setShowDropdown(false);
    }
  };

  const handleFocus = (e) => {
    setShowDropdown(true);
    e.target.select();
    // Открываем дропдаун при фокусе
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 150); // Закрываем дропдаун при потере фокуса
  };

  const handleSelect = (airportCode, displayName) => {
    // Заменяем "TSE" на "NQZ" при передаче
    const correctedAirportCode = airportCode === "TSE" ? "NQZ" : airportCode;
    setSearchTerm(displayName);
    onSelect(correctedAirportCode); // Передаем измененный код
    setShowDropdown(false);
  };

  return (
    <div className="country-search">
      <input
        type="text"
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={searchTerm}
        onChange={handleChange}
        placeholder="Откуда"
        autoComplete="off"
      />

      {error && <p style={{ color: "red" }}>{error}</p>}

      {showDropdown && (
        <ul className="dropdown">
          {results.map((city) => (
            <li key={city.city.city_code} className="city-item">
              <strong>
                {city.city.city_name_ru} ({city.city.city_code})
              </strong>
              <ul className="airport-list">
                {city.airports.map((airport) => (
                  <li
                    key={airport.airport_code}
                    onClick={() =>
                      handleSelect(
                        airport.airport_code,
                        `${city.city.city_name_ru}, ${airport.airport_name_ru} (${airport.airport_code})`
                      )
                    }
                    className="airport-item"
                  >
                    {airport.airport_name_ru} ({airport.airport_code})
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ToCity;
